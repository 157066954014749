<template>
  <ul :class="isRootLevel ? 'a-megamenu-items__main' : 'a-megamenu-items__list'">
    <AMegaMenuItem
      v-for="category in categories"
      :key="category.id"
      :link="category.link"
      :name="category.name"
      :is-mobile="isMobile"
      :has-children="category.items.length > 0"
      :item-id="Number(category.pimcore_id) || 0"
      :is-show-preview="isRootLevel"
      :class="{ 'a-megamenu-item--main': isRootLevel }"
      :data-transaction-name="isRootLevel ? 'MM - Root Level' : 'MM - Level'"
      @click="$emit('click', category)"
      @close="$emit('close')"
    >
      <div
        v-if="levelsInfo[level] === category.id"
        :class="isRootLevel ? 'a-megamenu-items__submenu' : 'a-megamenu-items__submenu-item'"
      >
        <ASubmenuHeader
          :name="category.name"
          data-transaction-name="MM - Back"
          @click="$emit('back')"
        />

        <AMegamenuItems
          v-if="category.items.length > 0"
          :categories="category.items"
          :level="level + 1"
          :levels-info="levelsInfo"
          :is-mobile="isMobile"
          @click="$emit('click', $event)"
          @close="$emit('close')"
          @back="$emit('back')"
        />

        <div
          v-if="isRootLevel"
          class="a-megamenu-items__submenu-img"
        >
          <div class="a-megamenu-items__img-top">
            <MBannerMenu
              :category-id="category.id"
              @close="$emit('close')"
            />
          </div>
          <div
            v-if="category.brand_menu"
            class="m-megamenu__brands"
          >
            <NoSSR>
              <MBrandMenu :items="category.brand_menu" />
            </NoSSR>
          </div>
        </div>
      </div>
    </AMegaMenuItem>
  </ul>
</template>

<script>
import AMegaMenuItem from 'theme/components/atoms/a-megamenu-item';
import ASubmenuHeader from 'theme/components/atoms/a-submenu-header';
import MBannerMenu from 'theme/components/molecules/m-banner-menu';
import NoSSR from 'vue-no-ssr';

const FIRST_LEVEL = 1;

export default {
  name: 'AMegamenuItems',
  components: {
    MBannerMenu,
    ASubmenuHeader,
    MBrandMenu: () => process.browser ? import('theme/components/molecules/m-brand-menu') : null,
    AMegaMenuItem,
    NoSSR
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      required: true
    },
    levelsInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    isRootLevel () {
      return this.level === FIRST_LEVEL;
    }
  }
}
</script>
