<template>
  <div class="m-mobile-menu">
    <AMegamenuItems
      :categories="categories"
      :level="firstLevel"
      :levels-info="levelsInfo"
      :is-mobile="isMobile"
      @click="onClick"
      @close="onClose"
      @back="onBack"
    />
  </div>
</template>

<script>
import AMegamenuItems from 'theme/components/atoms/a-megamenu-items';
import { mapMutations } from 'vuex';

const FIRST_LEVEL = 1;

export default {
  name: 'MMobileMenu',
  components: {
    AMegamenuItems
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeLevel: FIRST_LEVEL,
      levelsInfo: {}
    }
  },
  computed: {
    firstLevel () {
      return FIRST_LEVEL;
    }
  },
  methods: {
    ...mapMutations('ui', ['closeCatalog']),
    incrementLevel () {
      this.activeLevel += 1;
    },
    decrementLevel () {
      this.activeLevel -= 1;
    },
    onClose () {
      this.resetCurrent();

      if (!this.isMobile) {
        this.closeCatalog();
      }
    },
    onBack () {
      if (this.activeLevel === FIRST_LEVEL) {
        return;
      }

      this.decrementLevel();
      this.$delete(this.levelsInfo, this.activeLevel);
    },
    onClick (category) {
      this.$set(this.levelsInfo, this.activeLevel, category.id);
      this.incrementLevel();
    },
    resetCurrent () {
      this.activeLevel = FIRST_LEVEL;
      this.levelsInfo = {};
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-mobile-menu {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  visibility: visible;
  opacity: 1;
  top: 0;
  height: 100%;
  background-color: var(--white);

  ::v-deep .a-megamenu-items {
    &__main {
      padding: var(--spacer-sm) var(--spacer-20);
      margin: 0;
    }

    &__list {
      padding: 0;
      list-style: none;

      .a-megamenu-item:not(:last-child) {
        .a-megamenu-item__link {
          @include for-mobile {
            color: var(--black);
          }
        }
      }

      .a-megamenu-item:last-child {
        border-bottom: none;

        .a-megamenu-item__link {
          color: var(--orange);
        }
      }
    }

    &__submenu {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding: 0 var(--spacer-20) var(--spacer-20) var(--spacer-20);
      box-sizing: border-box;
      background: var(--white);
      z-index: 3;
      overflow-y: scroll;
      overscroll-behavior: contain;

      .a-megamenu-item__link {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: var(--font-sm) 0;

        @include for-desktop {
          padding: var(--spacer-xs) var(--spacer-xl) var(--spacer-xs) 0;
          box-sizing: border-box;
          cursor: pointer;
          border-bottom: none;
        }
      }
    }

    &__submenu-item {
      position: fixed;
      left: 0;
      top: 0;
      padding: 0 var(--spacer-20);
      width: 100%;
      height: calc(100% - var(--bottom-navigation-height));
      box-sizing: border-box;
      background: var(--white);
      color: var(--black);
      overflow-y: scroll;
      z-index: 4;
    }

    &__img-top {
      margin-top: 30px;
    }

    &__img-bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
