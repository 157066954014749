<template>
  <div class="a-submenu-header">
    <SfButton
      data-transaction-name="MM - Go To Back"
      @click="goToBack"
      class="sf-button--close a-submenu-header__button"
    >
      <span class="a-submenu-header__button-back" />
    </SfButton>
    <p class="a-submenu-header__title">
      {{ name }}
    </p>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';

export default {
  name: 'ASubmenuHeader',
  components: { SfButton },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  methods: {
    goToBack () {
      this.$emit('click')
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.a-submenu-header {
  display: flex;
  border-bottom: 1px solid var(--line-color);
  padding: var(--spacer-14) var(--spacer-20) var(--spacer-14) 0;
  align-items: center;

  &__title {
    font-size: var(--font-size-22);
    font-weight: var(--font-bold);
    font-family: var(--font-family-headers);
    text-transform: uppercase;
    margin: 0 0 0 var(--spacer-15)
  }

  &__button {
    width: var(--spacer-30);
    min-width: var(--spacer-30);
    height: var(--spacer-30);
    background: var(--light-gray);
    border-radius: 5px;
    --button-padding: var(--spacer-xs);

    &-back {
      @include font-icon(var(--icon-arrow-bold-left));

      &:before {
        color: var(--black);
        font-size: var(--font-size-12);
      }
    }

    &.sf-link, &.sf-link:active, &.sf-link:hover, &.sf-link:focus {
      background: var(--light-gray);
    }
  }
}
</style>
